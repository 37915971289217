@import "~assets/styles/variables";

.form-item-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__about {
    width: 100%;
    max-width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    &-item {
      font-size: 1.5rem;
      font-family: $m; } }
  &__exit {
    margin-left: auto;
    margin-top: auto; } }
