@import '~assets/styles/variables';

.menus {
  &__title {
    margin-bottom: 35px!important; }
  &__subtitle {
    margin-bottom: 25px!important; }
  &__icon {
    font-size: 2rem!important;
    margin-left: 7px;
    cursor: pointer; } }
