@import "~assets/styles/variables";
.media {
  padding: 20px;
  &__list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    &-item {
      position: relative;
      width: 200px;
      height: 200px;
      transition: all 0.3s ease;
      border: 1px solid $light-grey;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0,0, 0.3);
        .media__list-item-actions {
          visibility: visible;
          opacity: 1; } }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: all 0.3s ease;
        left: 0;
        top: 0;
        z-index: 2; }
      &--selected {
        transform: scale(1.03);
        &:hover {
          .media__list-item-actions {
            visibility: hidden;
            opacity: 0; } }
        .media__list-item-selected-icon {
          visibility: visible;
          opacity: 1; }
        &::before {
          opacity: 0.9;
          visibility: visible;
          pointer-events: none; } }
      &-selected-icon {
        position: absolute!important;
        z-index: 4;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 80px!important;
        height: 80px!important;
        background: transparent;
        opacity: 0;
        visibility: hidden; }
      &-actions {
        width: 40px;
        height: 120px;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease;
        position: absolute;
        right: 5px;
        display: flex;
        top: 5px;
        padding: 5px;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        &-item {
          width: 100%;
          height: 30px;
          background: $blue;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:not(:first-child) {
            margin-top: 10px; }
          & svg {
            font-size: 18px!important;
            & path {
              fill: #fff; } } } } }
    &-image {
      width: 60%!important;
      height: 60%!important;
      object-fit: contain;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.3s ease;
      right: 0;
      bottom: 0;
      margin: auto; } }
  &__total-count {
    margin: 30px 0!important; }
  &__bottom {
    margin-top: 50px!important;
    box-shadow: 0px 5px 3px -5px rgba(80, 80, 80, 0.6) inset;
    padding-top: 20px; } }

.file-uploader {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 91;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0; }
  &__container {
    position: static;
    overflow-y: auto;
    z-index: 2; }
  &__header {
    position: relative!important;
    background-color: $white!important;
    color: $black!important; }
  &__title {
    margin-left: 20px!important;
    font-size: 2rem!important;
    flex: 1!important; }
  &__media-title {
    margin: 20px 0 0 20px!important;
    color: $black!important;
    &--nomargin {
      margin-top: 0!important; } }
  &__dropzone {
    display: block;
    &--hide {
      display: none; }
    .MuiDropzoneArea-icon {
      width: 150px!important;
      height: 150px!important; } }
  &__confirm-btn {
    margin-right: 20px!important; }
  &__add-icon {
    margin-right: 5px; } }
