@import "~assets/styles/variables";
.menu-item-page {
  &__container {
    display: flex;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    width: 100%;
    border: 1px solid black;
    padding: 0px 20px;
    border-radius: 10px; }
  &__link-icon {
    font-size: 20px; }
  &__text--padding {
    padding-right: 20px!important; }
  &__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center; } }
