@import "~assets/styles/variables";

.html-editor {
  .rdw-editor-main {
    border-radius: 4px; }
  &--error {
    .rdw-editor-main {
      border: 1px solid #f44336; }
    &-text {
      color: #f44336;
      margin-top: 3px;
      display: block;
      margin-left: 14px;
      margin-right: 14px;
      font-size: 0.75rem; } }
  .public-DraftEditorPlaceholder-root {
    padding-left: 14px; } }
