@import '../../assets/styles/variables';

.notification {
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 250px;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  background: rgb(253, 236, 234);
  z-index: 99999;
  &--info {
    background: rgb(232, 244, 253); }
  &--warning {
    background: rgb(255, 244, 229); }
  &--success {
    background: rgb(237, 247, 237); }
  &__row {
    display: flex;
    align-items: flex-start; }
  &__icon {
    margin-right: 15px;
    max-width: 30px;
    width: 100%; }
  &__title {
    font: 1.9rem $b;
    line-height: 100%; }
  &__text {
    font: 1.4rem $r;
    margin-top: 5px; } }
