@import "~assets/styles/variables.sass";
.main-template {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &__container {
    margin-bottom: 30px; }
  &__page {
    flex: 1; }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &__button {
    width: 45px!important;
    height: 45px!important;
    border-radius: 50%!important;
    display: flex!important;
    padding: 5px!important;
    align-items: center;
    justify-content: center;
    min-width: 45px!important; }
  .to-top {
    width: 60px;
    height: 60px;
    background: #fff;
    position: fixed;
    bottom: -30px;
    right: 30px;
    z-index: 99;
    border-radius: 10px 10px 0 0;
    display: flex;
    border: 2px solid $black;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    .MuiSvgIcon-root {
      width: 40px;
      height: auto;
      path {
        fill: $black; } } } }
