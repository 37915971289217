@import "~assets/styles/variables";
.input-auto-size {
  margin-top: 20px;
  width: 100%;
  min-height: 200px;
  padding: 10.5px 14px!important;
  font: 1.4rem $r!important;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.00938em;
  &:placeholder {
    color: rgba(0, 0, 0, 0.54); } }
