.loader {
  &--active {
    svg {
      display: block!important; }
    .loader__text {
      opacity: 0; } }
  svg {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); } }
