@import "~assets/styles/variables.sass";

.form {
  flex: 1;
  &__title {
    font: 1.6rem $m!important;
    color: $black!important; }
  &__row {
    margin-bottom: 30px; }
  &__wrapper {
    height: 100%;
    &-tabs {
      position: relative;
      display: grid;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;
      margin-top: 20px;
      &::-webkit-scrollbar {
        height: 10px;
        background: #fff;
        border: 1px solid $black; }
      &::-webkit-scrollbar-thumb {
        background-color: $black;
        border-radius: 4px; } }
    &-title {
      margin-top: 0!important;
      padding: 10px 5px;
      cursor: pointer;
      min-width: 150px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: #fff;
      border: 1px solid $black;
      transition: all 0.3s ease;
      &:not(:last-child) {
        margin-right: 10px; }
      &:hover {
        color: #fff;
        background: $black!important; }
      &--active {
        background: $black!important;
        color: #fff!important; } } }
  &__container {
    margin-top: 20px;
    display: none;
    &--active {
      display: block; } } }
