@import '~assets/styles/variables.sass';

.settings-page {
  &__field {
    margin-bottom: 15px!important; }
  .MuiTypography-h4 {
    margin: 0 0 25px; }
  &__general {
    margin-bottom: 25px!important; }
  &__button {
    margin-top: 70px!important;
    max-width: 300px;
    width: 100%; } }
