@import "~assets/styles/variables";

.update-post-category-page {
  height: 100%;
  &__title {
    margin-bottom: 20px!important;
    box-shadow: 0 4px 1px -4px $light-grey; }
  &__container {
    padding-top: 30px!important;
    margin: 0 auto!important; }
  &__main {
    padding-right: 30px;
    &-title {
      margin-bottom: 30px!important; }
    & &-title-input {
      font-size: 3.6rem!important;
      text-align: center!important;
      font-family: $r!important; } }
  &__aside {
    padding: 0 10px;
    border-left: 1px solid $light-grey;
    &-link {
      font-size: 1.5rem!important;
      color: $black;
      text-decoration: none;
      word-wrap: break-word; } }
  &__seo {
    margin-top: 50px; } }
