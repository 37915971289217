@import '~assets/styles/variables.sass';

.form-item-file {
  &__block {
    cursor: pointer;
    width: 150px;
    height: 150px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    border: 1px solid $black;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      background: rgba(0, 0,0, 0.4);
      transition: all 0.3s ease; }
    &-delete-icon {
      width: 90px!important;
      height: 90px!important;
      position: absolute!important;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      visibility: hidden;
      opacity: 0;
      transition: all 13s ease;
      &:active {
        transform: scale(0.8)!important; } }
    &:hover {
      background: rgba(109, 103, 191, 0.1);
      &::before {
        visibility: visible;
        opacity: 1; }
      .form-item-file__block-delete-icon {
        visibility: visible;
        opacity: 1; } } }
  &__sizes {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 500; }
  &__image {
    width: 100%!important;
    height: 100%!important;
    object-fit: contain; }
  &__close-icon {
    right: 12px;
    top: 8px;
    position: absolute!important; }
  .MuiButton-label {
    text-transform: none; } }
body .MuiDropzoneArea-icon {
  width: 100px!important;
  height: 100px!important; }
