@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  src: url('../fonts/HelveticaNeueCyr-Roman.eot');
  src: url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueCyr-Medium';
  src: url('../fonts/HelveticaNeueCyr-Medium.eot');
  src: url('../fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Medium.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueCyr-Bold';
  src: url('../fonts/HelveticaNeueCyr-Bold.eot');
  src: url('../fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), url('../fonts/HelveticaNeueCyr-Bold.woff') format('woff'), url('../fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }
