$black: #1d1d1b;
$white: #ffffff;
$light-grey: #949494;
$grey: #666666;
$green: #21d621;
$red: #c50000;
$blue: #4359d5;
$dark-blue: #3f51b5;

$r: 'HelveticaNeueCyr-Roman';
$m: 'HelveticaNeueCyr-Medium';
$b: 'HelveticaNeueCyr-Bold';
