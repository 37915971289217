@import "~assets/styles/variables";

.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 25px;
  background: $black;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  &__logo-link {
    display: flex;
    justify-content: center;
    padding: 25px 0 20px; }
  &__logo {
    width: 100%;
    max-width: 100px; }
  &__actions {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end; }
  &__logout {
    cursor: pointer;
    svg {
      font-size: 30px!important; }
    path {
      fill: $white; } }
  &__role {
    text-align: center;
    margin: 0 40px; }
  &__user-role {
    font: 1rem $r;
    color: $light-grey; }
  &__name {
    font: 1.7rem $r;
    color: $white; }
  &__lang {
    position: relative; }
  &__lang-list {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -10px;
    top: 40px;
    width: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 333;
    transition: all 0.3s ease;
    height: auto;
    border-radius: 10px;
    padding: 10px 5px;
    background: #fff;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.2); }
  &__lang-item {
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      width: 100%; }
    &:not(:first-child) {
      margin-top: 10px; } }
  &__lang-items {
    cursor: pointer;
    width: 25px;
    &--active {
      .header__lang-list {
        opacity: 1;
        visibility: visible; } } } }
