@import "~assets/styles/variables";

.auth {
  flex: 1!important;
  background: #fff;
  &__logo {
    max-width: 150px; }
  &__input {
    background: #fff;
    border-radius: 5px;
    &:nth-child(2) {
      margin: 20px 0; } }
  &__form {
    max-width: 400px;
    margin-top: 30px; }
  &__loader {
    margin-top: 20px; }
  &__button {
    width: 100%;
    font-size: 18px!important;
    color: #fff!important;
    background: $black!important; }
  &-reset-password {
    &-title {
      color: $blue;
      font-size: 1.3rem!important;
      cursor: pointer;
      font-family: $b!important;
      display: inline-block;
      margin: 0 auto!important; } } }
