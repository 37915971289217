@import "~assets/styles/variables";

.users-page {
  &__table-head-item {
    text-align: right!important; }
  & .users-page__table-body-item {
    color: $red!important;
    font-family: $m!important; }
  & .users-page__table-body-item--active {
      color: $green!important; }
  &__buttons {
    text-align: right!important; }
  &__buttons-item {
    cursor: pointer; } }
