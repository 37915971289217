@import '~assets/styles/variables';

.post-categories-page {
  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr); }
  &__title {
    margin-bottom: 35px!important; }
  &__subtitle {
    margin-bottom: 25px!important; }
  &__field {
    margin-bottom: 15px!important; }
  &__icon {
    font-size: 2rem!important;
    margin-left: 7px;
    cursor: pointer; }
  &__list {
    grid-template-columns: repeat(5, 1fr); } }
