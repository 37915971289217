@import '~assets/styles/variables';

.admin-footer {
  padding: 15px 25px;
  background: $black;
  position: relative;
  z-index: 8;
  &__copyright {
    font: 12px $r;
    color: $white; } }
