@import "~assets/styles/variables";

.pages {
  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr); }
  &__table-head {
    padding: 20px 0; }
  &__title {
    margin-bottom: 35px!important; }
  &__subtitle {
    margin-bottom: 25px!important; }
  &__field {
    margin-bottom: 15px!important; } }
