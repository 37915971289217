@import "~assets/styles/variables";
.not-found-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__container {
    width: 100;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  &__title {
    margin-top: -60px;
    font: 4rem $m; }
  &__link {
    color: #fff;
    font: 2rem $m;
    padding: 20px;
    background: $dark-blue;
    max-width: 300px;
    text-align: center; } }
