@import "~assets/styles/variables";

.sidebar {
  width: 300px;
  background: $black;
  left: 0;
  top: 0;
  padding-bottom: 44px;
  z-index: 3;
  transition: all 0.3s ease;
  min-height: 100%;
  &--hide {
    transform: translateX(-100%);
    width: 0;
    .sidebar__menu {
      opacity: 0;
      visibility: hidden; } }
  &__menu {
    height: 100%;
    padding: 20px 15px 0;
    height: 100%;
    width: 100%;
    &-item {
      display: flex!important;
      align-items: center!important;
      font-size: 14px!important;
      color: $white!important;
      padding: 10px 15px!important;
      text-decoration: none!important;
      cursor: pointer;
      background: none!important;
      border: none!important;
      &:not(:first-child) {
        margin-top: 10px; }
      &--child {
        padding-left: 30px!important; }
      &-icon {
        margin-right: 20px;
        width: 20px!important;
        height: 20px!important;
        path {
          fill: $white; } }
      &-arrow {
        margin-left: auto;
        transition: all 0.3s ease;
        &--rotate {
          transform: rotate(90deg)!important; }
        svg path {
          fill: #fff; } } } }
  &__handle {
    height: 100vh;
    position: absolute;
    left: 285px;
    width: 30px;
    top: -77px;
    transition: all 0.3s ease;
    &-button {
      width: 30px;
      height: 30px;
      position: relative;
      background: $black;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      svg {
        transition: all 0.3s ease;
        width: 20px;
        height: auto;
        path {
          fill: $white; } } }
    &--hide {
      left: -15px;
      svg {
        transform: rotate(-180deg); } } } }
