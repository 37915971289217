@import './fonts';
@import "./variables";

html, body {
  height: 100%;
  font-size: 10px; }

body {
  margin: 0;
  font-family: $r; }

#root {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden; }

.main {
  flex: 1;
  display: flex;
  transition: all 0.3s ease;
  position: relative;
  &__in {
    padding: 30px;
    width: calc(100% - 300px);
    transition: all 0.3s ease;
    &--full {
      width: 100%; } } }

* {
  box-sizing: border-box;
  outline: none; }

ul, ol, p, h1, h2, h3, h4, h5, h6 {
  padding: 0; }

a {
  text-decoration: none; }

li {
  list-style: none; }

img {
  max-width: 100%;
  height: auto; }

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1; }

.MuiTableCell-root {
  font: 1.4rem $r!important;
  color: $black!important;
  border-bottom: 1px solid rgba(0, 0, 0, .4)!important; }

.MuiTableCell-head {
  font: 1.4rem $m!important; }

.MuiFormControl-root {
  width: 100%!important; }

.MuiFormLabel-root {
  font: 1.4rem $r!important; }

.MuiInputBase-root {
  font: 1.4rem $r!important; }

.MuiButton-root {
  font: 1.3rem $m!important;
  min-width: 200px;
  height: 35px; }

.fluid {
  width: 100%!important; }

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, .4)!important; }

.MuiTypography-body2 {
  font-size: 1.2rem!important; }
.MuiSvgIcon-root {
  font-size: 2rem!important; }
.MuiPaper-outlined {
  border-color: rgba(0, 0, 0, .4)!important;
  border-radius: 5px!important;
  padding: 25px; }
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336!important; }

.table-last-item {
  text-align: right!important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & .table-icon, & svg {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 20px!important; } }
  & > span {
    margin-right: 20px;
    width: 20px;
    display: block;
    .table-icon {
      width: 100%;
      height: auto;
      transition: all 0.3s ease;
      &--active {
        transform: rotate(-180deg); } } } }

.table {
  &-link {
    color: $black!important; }
  &-icon {
    display: inline-block!important;
    cursor: pointer;
    &--black {
      path {
        fill: #000; } } } }

.MuiButton-contained.Mui-disabled {
  background-color: #3f51b5!important;
  color: #fff!important; }

.modal {
  &-item {
    margin-bottom: 20px!important; } }

.select {
  &__item {
    font-size: 16px!important; } }

.nestable {
  &__list {
    display: grid;
    padding: 20px 0;
    border-bottom: 1px solid $light-grey; }
  &__title {
    font-family: $m;
    font-size: 1.6rem; }
  &__text {
    font-size: 1.4rem;
    font-family: $r;
    display: flex;
    align-items: center; }
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__button {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer; }
  &-item {
    cursor: move; } }

.empty-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.rdw-editor-main {
  border: 1px solid #F1F1F1; }

.public-DraftStyleDefault-block {}

.DraftEditor-editorContainer {
  padding: 0 14px; }

.public-DraftEditor-content {
  min-height: 120px; }

.form-field {
  &__title {
    font: 1.8rem $m;
    color: $black;
    margin-top: 20px; }
  &:not(:first-child) {
    margin-top: 20px; }
  &--switich {
    margin-left: 0!important; } }

.page {
  &__component-list {
    margin: 0;
    &-item:not(:first-child) {
      margin-top: 10px; }
    &-item-link {
      font: 1.4rem $m;
      color: $black!important; } } }
.page-table {
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      height: auto; }
    &-icon {
      width: 100%;
      object-fit: cover; } } }

.page-form {
  &__actions {
    margin-top: 30px; }
  &__file {
    margin: 20px 0;
    width: 150px;
    height: 150px;
    display: flex;
    position: relative;
    align-items: center;
    padding: 10px;
    border: 1px solid $light-grey;
    img {
      width: 100%!important; }
    &:hover {
      .page-form__file-delete {
        visibility: visible!important;
        opacity: 1!important; } } }
  &__file-delete {
    visibility: hidden;
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center;
    height: 100%;
    svg {
      cursor: pointer;
      width: 40px;
      height: auto; } } }

.media {
  &__block {
    width: 100%; }
  &-block__title {
    margin-bottom: 20px!important; }
  &-item-title {
    text-align: center!important;
    width: 100%; } }

.editorClassName {
  font-size: 1.7rem!important; }

.action-button {
  margin-top: 20px!important; }

.post-page__media {
  border-left: 1px solid $light-grey;
  img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain; } }
