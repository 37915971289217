@import '~assets/styles/variables.sass';
.form-repeater {
  position: relative;
  padding-top: 60px;
  &--without-padding {
    padding-top: 0; }
  &--closed {
    padding-top: 0; }
  &__title {
    padding: 10px 20px!important;
    font-size: 20px!important;
    background: $black;
    position: absolute!important;
    height: 60px;
    width: 100%;
    left: 0;
    top: 0;
    &:not(:first-child) {
      margin: 20px 0!important; }
    &-text {
      display: inline-block;
      cursor: pointer;
      color: $white;
      font-weight: 700!important;
      display: flex;
      align-items: center; } }
  &__container {
    margin-bottom: 0;
    background: #fff;
    display: flex;
    width: 100%;
    position: relative;
    padding: 0 80px 0 30px;
    &:not(:first-child) {
      margin-top: 20px; }
    &--border {
      border-bottom: 1px solid $light-grey;
      margin-bottom: 10px;
      padding-bottom: 20px; }
    &--none {
      display: none!important; }
    &:first-child {
      & .form-repeater__field-container {
        margin-top: 0; } } }
  &__field-container {
    flex: 1; }
  &__row {
    &:not(:first-child) {
      margin-top: 20px; }
    &--some-child {
      display: flex;
      grid-gap: 20px;
      width: 100%;
      .form-field {
        width: 100%;
        margin-top: 0!important; } }
    & .form-repeater .form-repeater__container {
      width: calc(100% + 110px);
      margin-left: -30px;
      padding-left: 45px; }
    & .form-repeater__switch {
      left: 10px; } }
  &__add {
    margin-top: 20px;
    padding: 5px; }
  .nestable-item-name {
    .form-field--some {
      width: 50%;
      display: inline-block; } }
  &--some {
    width: 50%;
    margin-top: 0!important; }
  &__delete {
    color: $red;
    cursor: pointer;
    height: 60px;
    width: 40px;
    position: absolute!important;
    right: 0;
    top: 0;
    svg {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto 0;
      width: 25px;
      height: auto; } }
  &__switch {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 40px;
    & .form-repeater__switch-icon {
      font-size: 3rem!important;
      width: 20px!important;
      height: 20px!important;
      cursor: pointer;
      path {
        fill: $grey; } }
    & .form-repeater__switch-icon--disabled {
      path {
        fill: $light-grey; } } } }
.form-repeater--conditions {
  .form-repeater {
    &__container {
      margin: 20px 0!important;
      .form-title {
        display: inline-block;
        cursor: pointer;
        color: $white;
        font-weight: 700!important;
        background: $light-grey;
        width: 100%;
        font-size: 16px!important;
        padding: 10px 20px !important; } } } }
